module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrica/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":94115375,"clickmap":true,"trackLinks":true,"accurateTrackBounce":true,"webvisor":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sber Automotive Technologies","short_name":"SberAutoTech","icon":"src/images/favicon.png","display":"minimal-ui","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8250e88ca3e6f55d4ff43ec5d62f9d1d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
