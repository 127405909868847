exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-company-tsx": () => import("./../../../src/pages/about-company.tsx" /* webpackChunkName: "component---src-pages-about-company-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-fleet-tsx": () => import("./../../../src/pages/fleet.tsx" /* webpackChunkName: "component---src-pages-fleet-tsx" */),
  "component---src-pages-flip-tsx": () => import("./../../../src/pages/flip.tsx" /* webpackChunkName: "component---src-pages-flip-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-[code]-tsx": () => import("./../../../src/pages/news/[code].tsx" /* webpackChunkName: "component---src-pages-news-[code]-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-self-driving-car-tsx": () => import("./../../../src/pages/self-driving-car.tsx" /* webpackChunkName: "component---src-pages-self-driving-car-tsx" */),
  "component---src-pages-self-driving-truck-tsx": () => import("./../../../src/pages/self-driving-truck.tsx" /* webpackChunkName: "component---src-pages-self-driving-truck-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-vacancies-[id]-tsx": () => import("./../../../src/pages/vacancies/[id].tsx" /* webpackChunkName: "component---src-pages-vacancies-[id]-tsx" */),
  "component---src-pages-vacancies-special-[id]-tsx": () => import("./../../../src/pages/vacancies/special/[id].tsx" /* webpackChunkName: "component---src-pages-vacancies-special-[id]-tsx" */),
  "component---src-pages-vacancies-tsx": () => import("./../../../src/pages/vacancies.tsx" /* webpackChunkName: "component---src-pages-vacancies-tsx" */)
}

